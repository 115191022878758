import { Icon } from '@fluentui/react/lib/Icon';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { IJob } from '../../types/job';
import { IProject } from '../../types/project';
import './mywork.scss';

function MyWork() {
  let projects: IProject[] = [
    {
      icon: 'CodeEdit',
      title: 'Konsolidierung der Kundenservice-Lösungen mit Dynamics 365',
      branch: 'Energie',
      category: 'Dynamics 365',
      year: '2022',
      text: 'Schaffung eine zentralen Kundenservice-Lösung mit Integration und Replikation zu SAP, Erstellung von Korrespondenten, Archivieren von Dokumenten.',
      tools: 'Dynamics 365 Online, AzureFunctions, .net core',
    },
    {
      icon: 'CodeEdit',
      title: 'Einführung Dynamics 365 Marketing',
      branch: 'Beratung',
      category: 'Dynamics 365',
      year: '2021',
      text: 'Einführung von Dynamics 365 Marketing in bestehender Sales Umgebung. Individuelle Schnittstelle zur Webseite für die Übernahme der Leads aus Kontaktformularen.',
      tools: 'Dynamics 365 Online, LogicApps, AzureFunctions, .net core',
    },
    {
      icon: 'CodeEdit',
      title: 'Migration SharePoint Kundenordner',
      branch: 'Automotive',
      category: 'Dynamics 365, SharePoint 2016',
      year: '2021',
      text: 'Migrationskonzept und Realisierung einer Anwendung zur Reorganisation von 60.000 Kundenordnern aus Dynamics 365.',
      tools: 'SharePoint, Dynamics 365, .net, ShareGate, PowerShell',
    },
    {
      icon: 'CodeEdit',
      title: 'Zeiterfassung',
      branch: 'Beratung',
      category: 'Webanwendung, Azure, MongoDB',
      year: '2021',
      text: 'Implementierung eines Zeiterfassungssystem inkl. Reporting und Abbildung von Projektkontingenten.',
      tools: 'WebApi, AzureFunctions, .net core, React, TypeScript',
    },
    {
      icon: 'CodeEdit',
      title: 'Dynamics 365 Consultant',
      branch: 'Verlagswesen',
      category: 'Dynamics 365',
      year: '2018-2021',
      text: 'Weiterentwicklung und Betreuung von Dynamics 365 und der umgebenen .net Anwendungen / Schnittstellen',
      tools: 'Dynamics 365, .net, Scribe',
    },
    {
      icon: 'CodeEdit',
      title: 'Kundenportal',
      branch: 'Energie',
      category: 'Azure, SharePoint',
      year: '2018',
      text: 'Implementierung des auf Azure basierenden Kundenportals.',
      tools: 'AzureFunctions, .net core, React, TypeScript',
    },
    {
      icon: 'CodeEdit',
      title: 'Service Plattform',
      branch: 'Maschinenbau',
      category: 'Azure, SharePoint',
      year: '2017-2018',
      text: 'Implementierung einer SharePoint Online basierten, multilingualen und international einsetzbaren Case Management Lösung.',
      tools: 'Microsoft SharePoint, Azure Storage, Azure Search, Azure Translation API, .net, React, TypeScript',
    },
    {
      icon: 'CodeEdit',
      title: 'SharePoint Portal',
      branch: 'Automotive',
      category: 'SharePoint',
      year: '2017',
      text: 'Implementierung eines SharePoint Portals als zentraler Einstieg für die Mitarbeiter.',
      tools: 'Microsoft SharePoint, .net, React, TypeScript, Microsoft SQL Server',
    },
    {
      icon: 'CodeEdit',
      title: 'Dynamics CRM im Order to Cash Prozess',
      branch: 'Automotive',
      category: 'Dynamics CRM',
      year: '2017',
      text: 'Customizing und Entwicklung von Lösungen im Dynamics CRM Umfeld inkl. Integration in SAP.',
      tools: 'Microsoft Dynamics 365, .net, WebServices',
    },
    {
      icon: 'CodeEdit',
      title: 'Intranet',
      branch: 'Verlagswesen',
      category: 'SharePoint',
      year: '2016',
      text: 'Implementierung eines individuellen Intranets für die deutschsprachigen Mitarbeiter.',
      tools: 'Microsoft SharePoint, AngularJs, JavaScript',
    },
  ];

  return (
    <div className='innercontent mywork'>
      <h1>Meine Arbeit</h1>
      <h3>Ein Blick in mein Arbeitsleben</h3>
      <div className='text'>
        <p>
          In mehr als zwanzig Jahren in der Softwareentwicklung im Microsoft Umfeld hatte ich die Chance in
          verschiedensten Branchen tätig zu sein. Neben vielen Jahren im Verlags -und Medienumfeld konnte ich in den
          vergangenen Jahren eine Vielzahl von erfolgreichen Projekten in anderen Branchen umsetzen.
        </p>
        <p>
          Über die Jahre war ich in verschiedenen Rollen tätig. Dabei in den letzten 10 Jahren immer mit
          Personalverantwortung. Die Weiterbildung und Ausbildung der Mitarbeiter war mir dabei genauso wichtig, wie
          meine eigene Weiterentwicklung. Durch meine sehr schnelle Auffassungsgabe und mein Prozessverständnis bin ich
          auch immer im Rahmen des Anforderungsmanagements tätig.
        </p>
        <p>
          Seit 2017 bin ich für die{' '}
          <a href='https://www.tuleva.de/' target='_blank'>
            Tuleva AG
          </a>{' '}
          in Hamburg tätig. Im Rahmen diverser Kundensituationen im Microsoft Umfeld biete ich meinen Kunden optimale
          Lösungen für ihre Herausforderungen.
        </p>
      </div>
      <div className='text'>
        <h4>Branchen:</h4>
        Automotive, Beratung, Energie, Handel, Logistik, Medien, Verlagswesen
      </div>
      <div className='text'>
        <h4>Kompetenzfelder:</h4>
        Lösungsarchitektur, Softwarearchitektur, Azure, PowerPlattform, Dataverse, Dynamics 365, Microsoft 365,
        SharePoint, .net Entwicklung, moderne Webanwendungen mit React oder Angular, Azure Devops, Git
      </div>
      <div className='text'>
        <h4>Programmiersprachen:</h4>
        .net, .net Core, C#, TypeScript, JavaScript, SQL
      </div>
      <div className='text'>
        <h4>Softskills:</h4>
        Teamfähigkeit, Problemlösungskompetenz, Schnelle Auffassungsgabe, Selbständigkeit, Flexibilität, Belastbarkeit,
        Wissensvermittlung
      </div>
      <hr></hr>
      <h2>Auszug aus meinen Projekten:</h2>
      <div className='projects'>
        <VerticalTimeline lineColor='#000' layout='2-columns'>
          {projects.map((value: IProject, index: number) => {
            return (
              <VerticalTimelineElement
                key={index}
                date={value.year}
                iconStyle={{ background: '#00a2db', color: '#fff' }}
              >
                <h4 className='vertical-timeline-element-title'>{value.title}</h4> <br />
                <span className='projectdetailtitle'>Branche:</span> {value.branch}
                <br />
                <span className='projectdetailtitle'>Kategorie:</span> {value.category}
                <br />
                <span className='projectdetailtitle'>Tools: </span>
                {value.tools}
                <br />
                <p>{value.text}</p>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default MyWork;
