import React from 'react';
import './contact.scss';

function Contact() {
  return (
    <div className='innercontent contact'>
      <h1>Kontakt</h1>
      <h3>So erreichen Sie mich</h3>
    </div>
  );
}

export default Contact;
