import React from 'react';
import './aboutme.scss';
import moment from 'moment';
import { ITopic } from '../../types/topic';
import { Icon } from '@fluentui/react/lib/components/Icon';
import { IJob } from '../../types/job';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function AboutMe() {
  let myBirthDay = new Date(1977, 7, 27);

  let years = moment().diff(moment(myBirthDay), 'years');

  let topics: ITopic[] = [
    {
      title: 'Software Architektur',
      icon: 'ModelingView',
      text: 'Komplexe systemübergreifende Architekturen und komplexe Softwarearchitekturen gehören zu meinen spannendsten Aufgaben.',
    },
    {
      title: 'Dynamics 365',
      icon: 'BarChartVertical',
      text: 'Beratung und Entwicklung in und auf Basis von Dynamics 365 Sales, Marketing oder Service Module.',
    },
    {
      title: 'Software Entwicklung',
      icon: 'DeveloperTools',
      text: 'Objektorientierte Softwareentwicklung mit .net oder TypeScript, React oder Angular. Immer mit den Prinzipien von Clean Code und der Wartbarkeit im Fokus.',
    },
    {
      title: 'Beratung',
      icon: 'UserEvent',
      text: 'Automatisierung & Digitalisierung von Prozessen ist eine wesentliche Aufgabe der heutigen Zeit.',
    },
  ];

  let jobs: IJob[] = [
    {
      company: 'Tuleva AG',
      position: 'HeadOf Microsoft Solutions',
      year: '2018 - heute',
      description: 'Verantwortung für den Microsoft Bereich innerhalb der Tuleva AG.',
      active: true,
    },
    {
      company: 'Tuleva AG',
      position: 'Senior Solution Architekt',
      year: '2017 - heute',
      description:
        'Betreuung verschiedenen Kunden im Microsoft Umfeld. Umsetzung komplexer Szenarien mit Microsoft Azure, Dynamics 365 und SharePoint.',
      active: true,
    },
    {
      company: 'Bauer Systems KG',
      position: 'Teamleiter .NET & SharePoint Entwicklung',
      year: '2013 - 2017',
      description:
        'Verantwortung für alle Entwicklungen im Microsoft-Umfeld. Architektur, Entwicklung und Betreuung von Lösungen.',
      active: false,
    },
    {
      company: 'Bauer Systems KG',
      position: 'Senior Software Developer',
      year: '2006 - 2013',
      description:
        'Architektur und Entwicklung von Lösungen im Medien -und Verlagsumfeld. Einführung von Microsoft Dynamics & SharePoint.',
      active: false,
    },
    {
      company: 'Bauer Vertriebs KG',
      position: 'Software Developer',
      year: '1999 - 2006',
      description: 'Entwicklung von Kundenservice bzw. Call-Center nahen Lösungen.',
      active: false,
    },
  ];

  return (
    <div className='innercontent aboutme'>
      <h1>Über mich</h1>
      <h3>{years} Jahre alt / verheiratet / ein Sohn</h3>
      <div className='text'>
        <p>
          Als erfahrener Softwarearchitekt und Entwickler mit der Leidenschaft für qualitativ hochwertige und wartbare
          Software-Systeme kann ich auf eine lange Liste von erfolgreichen Projekten zurückblicken.
        </p>
        <p>
          Durch meine umfangreiche Erfahrung in Azure, Dynamics 365 und SharePoint, kann ich Herausforderungen
          ganzheitlich bewerten und ideale Lösungen schaffen.
        </p>
      </div>
      <hr />
      <h2>Meine Schwerpunkte</h2>
      <div className='topics'>
        {topics.map((value: ITopic, index: number) => {
          return (
            <div key={index} className='topic'>
              <div>
                <Icon iconName={value.icon} className='icon' />
                <h4>{value.title}</h4>
                <p>{value.text}</p>
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <h2>Mein beruflicher Werdegang</h2>
      <div className='jobs'>
        <VerticalTimeline lineColor='#000' layout='2-columns'>
          {jobs.map((value: IJob, index: number) => {
            return (
              <VerticalTimelineElement
                key={index}
                date={value.year}
                iconStyle={{ background: '#00a2db', color: '#fff' }}
                // icon={<Icon iconName='WorkforceManagement' />}
              >
                <h4 className='vertical-timeline-element-title'>{value.position}</h4>
                <h3 className='vertical-timeline-element-subtitle'>{value.company}</h3>
                <p>{value.description}</p>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default AboutMe;
