import React, { useEffect, useState } from 'react';
import './home.scss';
import Menu from '../menu/menu';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contact from '../contact/contact';
import AboutMe from '../aboutme/aboutme';
import MyWork from '../mywork/mywork';
import Skills from '../skills/skills';
import ImageBlock from '../imageblock/imageblock';
import Header from '../header/header';
import Loading from '../loading/loading';
import Imprint from '../imprint/imprint';
import DataProtection from '../dataprotection/dataprotection';

// https://preview.themeforest.net/item/niko-resume-wordpress-theme/full_screen_preview/23936124?clickid=UgVQzyRJMxyIUCi1A8Rh1UuSUkGWiuy2lX2DSI0&iradid=275988&iradtype=ONLINE_TRACKING_LINK&irgwc=1&irmptype=mediapartner&irpid=369282&mp_value1=&utm_campaign=af_impact_radius_369282&utm_medium=affiliate&utm_source=impact_radius

function Home() {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
  }, [location]);

  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  return (
    <div className='home'>
      <Menu location={location.pathname}></Menu>
      <ImageBlock></ImageBlock>
      <div className='content'>
        <Header />
        {loading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path='/' element={<AboutMe />} />
            <Route path='kontakt' element={<Contact />} />
            <Route path='meinearbeit' element={<MyWork />} />
            <Route path='skills' element={<Skills />} />
            <Route path='impressum' element={<Imprint />} />
            <Route path='datenschutz' element={<DataProtection />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default Home;
