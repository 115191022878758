import React, { FC } from 'react';
import './menu.scss';
import { Icon } from '@fluentui/react/lib/Icon';

interface IMenuProps {
  location: string;
}

interface IMenuItem {
  className: string;
  url: string;
  title: string;
  icon: string;
}

const Menu: FC<IMenuProps> = (props: IMenuProps): JSX.Element => {
  const menuItems: IMenuItem[] = [
    {
      className: props.location === '/' ? 'selected' : '',
      url: '/#/',
      title: 'über mich',
      icon: 'Contact',
    },

    {
      className: props.location === '/meinearbeit' ? 'selected' : '',
      url: '/#/meinearbeit',
      title: 'meine arbeit',
      icon: 'Teamwork',
    },
    {
      className: props.location === '/skills' ? 'selected' : '',
      url: '/#/skills',
      title: 'skills',
      icon: 'Work',
    },
    // {
    //   className: props.location === '/kontakt' ? 'selected' : '',
    //   url: '/#/kontakt',
    //   title: 'kontakt',
    //   icon: 'Chat',
    // },
    {
      className: props.location === '/impressum' ? 'selected' : '',
      url: '/#/impressum',
      title: 'impressum',
      icon: 'TextDocument',
    },
    {
      className: props.location === '/datenschutz' ? 'selected' : '',
      url: '/#/datenschutz',
      title: 'datenschutz',
      icon: 'ProtectedDocument',
    },
    // {
    //   className: props.location === '/#' ? 'selected' : '',
    //   url: '/#',
    //   title: 'download cv',
    //   icon: 'Download',
    // },
  ];

  return (
    <>
      <nav className='menu'>
        <ul>
          {menuItems.map((value: IMenuItem, index: number) => {
            return (
              <li key={index}>
                <a href={value.url} title={value.title} className={value.className}>
                  <Icon iconName={value.icon} />
                </a>
              </li>
            );
          })}
        </ul>
        <div className='social'>
          <div>
            <a className='social-xing' href='https://www.xing.com/profile/Lars_Hildebrandt/cv' target='_blank'>
              <img src='images/xing.png' alt='Xing' />
            </a>
          </div>
          <div>
            <a
              className='social-linkedin'
              href='https://www.linkedin.com/in/lars-hildebrandt-6209a437/'
              target='_blank'
            >
              <img src='images/linkedin2.svg' alt='LinkedIn' />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Menu;
