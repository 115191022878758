import './header.scss';

import React from 'react';

function Header() {
  return (
    <div className='header'>
      <h2>Lars Hildebrandt</h2>
      <div className='smallimage' style={{ backgroundImage: 'url(images/larshildebrandt_small.jpg)' }}></div>
    </div>
  );
}

export default Header;
