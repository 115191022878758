import './imageblock.scss';

import React from 'react';

function ImageBlock() {
  return (
    <div className='imageblock' style={{ backgroundImage: 'url(images/larshildebrandt.jpg)' }}>
      <div className='name'>Lars Hildebrandt</div>
    </div>
  );
}

export default ImageBlock;
