import React from 'react';
import './loading.scss';

function Loading() {
  return (
    <div className='loadercomponent'>
      <div className='loader'>
        <div className='circle one'></div>
        <div className='circle two'></div>
        <div className='circle three'></div>
      </div>
    </div>
  );
}

export default Loading;
