import React from 'react';
import { ISkill } from '../../types/skill';
import './skills.scss';

function Skills() {
  let skills: ISkill[] = [
    {
      title: 'Software Development',
      text: 'Über 20 Jahre Erfahrung in der Microsoft .net Entwicklung mit C# und anderen Sprachen',
    },
    {
      title: 'Lösungsarchitektur',
      text: 'Die ',
    },
  ];

  return (
    <div className='innercontent skills'>
      <h1>Meine Skills</h1>
      <h3>Auf dieser Seite versuche ich meine wesentlichen Erfahrungen darzustellen.</h3>

      <div className='skillcloud' style={{ backgroundImage: 'url(images/skills.png)' }}></div>
    </div>
  );
}

export default Skills;
